<template>
  <transition-page>
    <router-view />
  </transition-page>
</template>

<script>
import TransitionPage from "@/components/Layout/TransitionPage";

export default {
  name: "dashboard",
  components: {
    TransitionPage,
  },
};
</script>
